// Import all SVG icons
import Announcements from "./Announcements";
import Engagement from "./Engagement";
import Hiring from "./Hiring";
import Home from "./Home";
import KnowledgeBase from "./KnowledgeBase";
import Pricing from "./Pricing";
import Training from "./Training";
import Privacy from "./Terms/Privacy";
import Terms from "./Terms/Terms";

const Pages = {
  Announcements,
  Engagement,
  Hiring,
  Home,
  KnowledgeBase,
  Pricing,
  Training,
  Privacy,
  Terms,
};

export default Pages;
