import React from "react";
import Illustrations from "../../assets/Illustrations";

const Engagement = () => {
  return (
    <div className="employee-journey-flex">
      <div className="employee-journey-flex-left Engagement-img">
        <img src={Illustrations.Engagement} alt="" />
      </div>
      <div className="employee-journey-flex-right">
        <h1>
          Uncover powerful insights about your workforce, and preempt churn, all
          without adding overhead.
        </h1>
        <p>
          Conduct regular monthly pulse checks and engagement surveys through
          chat to gain transparent insights into employee satisfaction.
        </p>
        <p>
          Utilize automation for check-ins, comprehensive 360 reviews, and
          performance reports.
        </p>

        <p>
          Identify the necessary actions to enhance your employee NPS and ensure
          the happiness of your workforce.
        </p>
      </div>
    </div>
  );
};

export default Engagement;
