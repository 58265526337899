import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
const Terms = () => {
  return (
    <>
      <div className="privacy-poilicy">
        <Navbar />
        <div className="privacy-poilicy-header">
          <h1>Terms of Service</h1>
        </div>
        <div className="privacy-content">
          <br /> This privacy policy will explain how Krishna Analytics Pte Ltd,
          t/a Knotify uses the personal data we collect from you when you use
          our product. <br />
          <br />
          It covers the following topics:
          <br />
          <br />
          <ul>
            <li> What data do we collect?</li>
            <li>How do we collect your data?</li>
            <li>How will we use your data?</li>
            <li>How do we store your data?</li>
            <li>Marketing</li>
            <li>What are your data protection rights?</li>
            <li>What are cookies?</li>
            <li>How do we use cookies?</li>
            <li>What types of cookies do we use?</li>
            <li>How to manage your cookies</li>
            <li>Privacy policies of other websites</li>
            <li>Changes to our privacy policy</li>
            <li>How to contact us</li>
          </ul>
          <br />
          <br />
          <b>What data do we collect?</b>
          <br />
          <br />
          Our Company collects the following data:
          <br />
          <br />
          <ul>
            <li> First name</li>
            <li>Last name</li>
            <li>Email address</li>
            <li>Mobile number</li>
            <li>
              Any other data you voluntarily upload to the Knotify product or
              any of our sub-processors
            </li>
          </ul>
          <br />
          <br />
          <b>How do we collect your data?</b>
          <br />
          <br />
          You directly provide our Company with most of the data we collect. We
          collect data and process data when you:
          <br />
          <br />
          <ul>
            <li> Register for a Knotify account</li>
            <li>Engage with Knotify via Intercom (a sub-processor)</li>
            <li>Submit a demo request via Calendly (a sub-processor)</li>
            <li>Use or view our website via your browser’s cookies</li>
          </ul>
          <br />
          <br />
          <b>How will we use your data?</b>
          <br />
          <br />
          Our Company collects your data so that we can provide our services to
          you. Additionally, we may share your data with subprocessors that
          support us in providing our services. We will engage only with
          subprocessors whose terms of service honor similar obligations to the
          ones outlined in this policy.
          <br />
          Here is the list of relevant subprocessors:
          <br />
          <br />
          <ul>
            <li> Cloudflare: CDN and Security</li>

            <li>Notion: Email & Collaboration</li>
            <li>Twilio: SMS and Whatsapp Integrations</li>
            <li>Auth0: Authentication & User Management</li>
            <li>Google Workspace: Business Apps & Collaboration Tools</li>
            <li>Stripe: Payments</li>
            <li>AWS Cloudwatch: Error Logging</li>
            <li>Freshworks: Customer Service</li>
            <li>Sendgrid: Emails</li>
            <li>Logrocket: Behavior Analytics</li>
            <li>Google Analytics: Web Analytics</li>
          </ul>
          <br />
          <br />
          <b>How do we store your data?</b>
          <br /><br />
          We store your data with an outsourced cloud infrastructure provider,
          Amazon Web Services (AWS). The databases are located in Singapore. We
          retain the collected data for the duration of your contract with us
          and for up to one year after contract termination.
          <br />
          <br />
          <b>Data Deletion</b>
          <br /><br />
          After the retention period has ended, the Company will securely delete
          all client data, including backups and archives. If the client
          requests deletion of their data before the end of the retention
          period, the Company will comply with the request in a timely manner,
          subject to any legal obligations.
          <br />
          <br />
          <b>Marketing</b>
          <br /><br />
          Our Company would like to send you information about products and
          services of ours that we think you might like, as well as those of our
          partner companies. If you have agreed to receive marketing, you may
          always opt out at a later date. You have the right at any time to stop
          Our Company from contacting you for marketing purposes.
          <br />
          <br />
          <b>What are your data protection rights?</b>
          <br /><br />
          Our Company would like to make sure you are fully aware of all of your
          data protection rights. Every user is entitled to the following:{" "}
          <br />
          <ul>
            <li>
              The right to access – You have the right to request Our Company
              for copies of your personal data.
            </li>
            <li>
              The right to rectification – You have the right to request that
              Our Company correct any information you believe is inaccurate. You
              also have the right to request Our Company to complete the
              information you believe is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that Our
              Company erase your personal data, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that Our Company restrict the processing of your personal data,
              under certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to Our Company’s processing of your personal data, under certain
              conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              Our Company transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
          </ul>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at our
          email: privacy@Knotify.io
          <br />
          <br />
          <b>Cookies</b>
          <br />
          <br />
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our websites, we may collect information from you automatically
          through cookies or similar technology. For further information, visit
          allaboutcookies.org.
          <br />
          <br />
          <b>How do we use cookies?</b>
          Our Company uses cookies in a range of ways to improve your experience
          on our website, including:
          <ul>
            <li>Keeping you signed in</li>
            <li> Understanding how you use our products</li>
          </ul>
          <br />
          <br />
          <b>What types of cookies do we use?</b>
          <br />
          <br />
          There are a number of different types of cookies, however, our website
          uses:
          <br />
          <br />
          <ul>
            <li>
              Functionality – Our Company uses these cookies so that we
              recognize you on our website and remember your previously selected
              preferences. These could include what language you prefer and
              location you are in. A mix of first-party and third-party cookies
              are used.
            </li>
            <li>
              Advertising – Our Company uses these cookies to collect
              information about your visit to our website, the content you
              viewed, the links you followed and information about your browser,
              device, and your IP address. Our Company sometimes shares some
              limited aspects of this data with third parties for advertising
              purposes. We may also share online data collected through cookies
              with our advertising partners. This means that when you visit
              another website, you may be shown advertising based on your
              browsing patterns on our website.
            </li>
          </ul>
          <br />
          <br />
          <b>How to manage cookies</b>
          <br />
          <br />
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser. However, in a few
          cases, some of our website features may not function as a result.
          <br />
          <br />
          <b>Privacy policies of other websites</b>
          <br />
          <br />
          The Our Company website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
          <br />
          <br />
          <b>Changes to our privacy policy</b>
          <br />
          <br />
          Our Company keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on
          30 April 2023.
          <br />
          <br />
          <b>Data Policy for Uploaded Data</b>
          <br />
          <br />
          Knotify provides an import service (manually from the UI, via API, via
          third-party bulk upload) allowing your users to upload data. The data
          policy here is applicable to the data uploaded by the users.
          <br />
          <br />
          <b>How to contact us</b>
          <br />
          <br />
          If you have any questions about Our Company’s privacy policy, the data
          we hold on you, or you would like to exercise one of your data
          protection rights, please do not hesitate to contact us.
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
