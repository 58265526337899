import React from "react";
import Illustrations from "../../assets/Illustrations";
import Navbar from "../../components/Navbar/Navbar";
import FlexCard from "../../components/FlexCard/FlexCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Icons from "../../assets/Icons";
import Footer from "../../components/Footer/Footer";
import Faq from "../../components/Faq/Faq";

const Index = () => {
  const data = [
    {
      img: Illustrations.Training1,
      header:
        "Bring your internal knowledge into one secure central hub with our AI-powered knowledge base.",
      points: [
        "Privacy first. All your data is secured in a SOC2-compliant app, only available to your workforce. The data is never used to train other models or shared with outside parties.",
        "In today's fast-paced world, having quick access to accurate information can make all the difference for a business. This is where knowledge bases come into play - they provide a central repository of information that employees and partners can easily access.",
        "Simply upload your internal knowledge articles and let the AI do its magic.",
      ],
      subtitle: "CONTENT ONBOARDING",
    },
    {
      img: Illustrations.Training2,
      header:
        "With an AI knowledge database, there are no excuses for non-compliance.",
      points: [
        `Make "I didn't know" and similar excuses obsolete with a full database of facts and procedures at your employee's fingertips.`,
        "Enrich AI replies with direct links to source materials, training videos, and more.",
      ],
      subtitle: "REDUCE ERRORS AND OVERHEAD",
    },
    {
      img: Illustrations.Training3,
      subtitle: "CONTINUOUS ENGAGEMENT",
      header: "Wave goodbye to one-off training that is soon forgotten.",
      points: [
        `Create a constant learning culture, with always-on, continuous engagement.`,
        "Create multi-step training and enrichment programs to elevate your team's knowledge, skills, and abilities.",
        "Set your campaign targeting, goals, and KPIs to drive employee performance, and watch as current and future employees are automatically enrolled and followed up by the software automation.",
      ],
    },
    {
      img: Illustrations.Training4,
      subtitle: "RICH ANALYTICS",
      header:
        "Gain insights on knowledge usage: queries, top performers, improvements.",
      points: [
        "Measure the effectiveness of your training programs and make real-time data-driven decisions to continuously improve and optimize your knowledge base.",
        "AI knowledge base ensures access to crucial information, enhancing efficiency.",
        "Moreover, by creating a continuous learning culture, businesses can promote constant growth and improvement among their employees. With AI-powered training programs that are always-on, employees can continuously enhance their knowledge, skills, and abilities.",
        "The inclusion of rich analytics also allows businesses to gain insights into their team's common pain points. Export data to perform your own analysis, and create summaries of all questions the current AI-powered knowledge base cannot answer, for future improvements.",
      ],
    },
    {
      img: Illustrations.KnowledgeBase5,
      subtitle: "ENGAGEMENT",
      header: "See higher engagement than any other avenue of communication.",
      points: [
        "You want the best and brightest applicants. To achieve this, you need to move with the times and approach them in the same way that they approach the world - through technology.",
        "Cut out the app downloads, email log-ins, and phone calls. Why spend money developing expensive hiring tools that don't work when you can achieve 4-5 times higher engagement by communicating with applicants where they already are?",
        "With Knotify, you can communicate with candidates through their preferred method - messages on platforms such as WhatsApp.",
      ],
    },
  ];
  return (
    <>
      <div className="knotify-landing-page-wrap">
        <Navbar />

        <div className="knotify-landing-page">
          <div className="knotify-landing-page-content | hiring-knotify-landing-page-content">
            <span>AI-POWERED KNOWLEDGE BASE</span>
            <h1>
              Empower frontline staff with instant access to vital information.
            </h1>
            <p>
              Connect all your recipes, policies, and SOPs to our AI knowledge
              base and build an oracle that can instantly answer any question.
            </p>
            <div className="buttons">
              {/* <button className="secondary">Product Tour</button> */}
              <button  onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}>Book your Demo !</button>
            </div>
          </div>
          <div className="knotify-landing-page-img">
            <img src={Illustrations.KnowledgeBaseLanding} alt="" />
          </div>
        </div>
      </div>
      <div className="knotify-landing-page-bottom | hiring-landing-page-bottom">
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">90%</div>
          <p>
            REDUCTION IN MANUAL <br /> COMMUNICATION
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">4-5x</div>
          <p>
            HIGHER ENGAGEMENT THAN <br /> EMAIL OR SEPARATE APPS
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">22%</div>
          <p>
            INCREASE IN EMPLOYEE <br /> ENGAGEMENT
          </p>
        </div>
      </div>
      <div className="app-client-wrap">
        <div className="hiring-left-right">
          {data.map((item) => {
            return (
              <FlexCard
                img={item.img}
                header={item.header}
                points={item.points}
                subtitle={item.subtitle}
                numberPoints={false}
              />
            );
          })}
        </div>

        {/* <div className="clients">
          <h1>
            What our Clients are <br /> saying about us!
          </h1>
          <div className="carousel-container">
            <Carousel
              showThumbs={false}
              autoPlay={true}
              pauseOnHover={false}
              showStatus={false}
              infiniteLoop={true}
              dynamicHeight={false}
              showIndicators={false}
              showArrows={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <div
                  onClick={onClickHandler}
                  className="left-arrow  | carousel-arrow"
                  // className={hasPrev ? "left-arrow  | carousel-arrow active-arrow" : "left-arrow  | carousel-arrow"}
                >
                  <div className="left-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.LeftArrowBlack} alt="" />
                  </div>
                </div>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <div
                  onClick={onClickHandler}
                  // className={hasNext ? "right-arrow  | carousel-arrow active-arrow" : "right-arrow  | carousel-arrow"}
                  className="right-arrow  | carousel-arrow  active-arrow"
                >
                  <div className="right-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.RightArrowWhite} alt="" />
                  </div>
                </div>
              )}
            >
              <div className="carousel-card">
                <p>
                  While the company wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the team devoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  Teamollo delivered the site with inthe timeline as they
                  requested. Inthe end, the client found a 50% increase in
                  traffic with in days since its launch. They also had an
                  impressive ability to use technologies that the company hasn`t
                  used, which have also proved to be easy to use and reliable.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div> */}
      </div>

      <div className="faq-container">
        <h1>
          Still unsure? <br />
          Find the answers you’re looking for.
        </h1>
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default Index;
