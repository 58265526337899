import React from "react";
import Illustrations from "../../assets/Illustrations";
import Navbar from "../../components/Navbar/Navbar";
import FlexCard from "../../components/FlexCard/FlexCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Icons from "../../assets/Icons";
import Footer from "../../components/Footer/Footer";
import Faq from "../../components/Faq/Faq";

const Index = () => {
  const data = [
    {
      img: Illustrations.Announcements1,
      header:
        "Cut through to your entire frontline staff within minutes when it's urgent!",
      points: [
        "Deliver vital information where it's needed on time.",
        "Send out opening times, last-minute changes to procedures, updates on health and safety regulations, and any other important messages instantly, using Knotify.",
        "Knotify is more than just a messaging tool - it's a comprehensive communication platform that streamlines the dissemination of vital information within your organization.",
        "With Knotify, you can easily create targeted groups based on job roles or departments, ensuring that the right people receive the right information at the right time.",
      ],
      subtitle: "IMPORTANT ANNOUNCEMENTS",
    },
    {
      img: Illustrations.Announcements2,
      header:
        "Keep everyone in the loop with regular updates, company news, and internal newsletters through Knotify.",
      points: [
        "Gone are the days of email inboxes overflowing with announcements and newsletters that go unread. With Knotify, you can send out your updates directly to employees' preferred chat app, increasing engagement and ensuring important information is not missed.",
        "In addition to messaging capabilities, Knotify can deliver links to essential polls and surveys, allowing you to track receipt of messages and gather feedback and opinions from your employees quickly and easily.",
      ],
      subtitle: "GENERAL COMMS",
    },
    {
      img: Illustrations.Announcements3,
      subtitle: "PULSE CHECKS",
      header: "Guage the effectiveness of your initiatives.",
      points: [
        "Run monthly or quarterly pulse checks through Knotify to better understand how your employees feel about various aspects of the organization.",
        "Use this valuable feedback to improve processes, boost morale, and address any concerns before they become bigger issues.",
        "Prevent turnover and maintain a happy, engaged workforce with Knotify's pulse check feature.",
      ],
    },
    {
      img: Illustrations.Announcements4,
      subtitle: "RECOGNITION",
      header:
        "Celebrate your employees. Show your appreciation with a strong recognition program.",
      points: [
        "With Knotify, you can recognize and celebrate your employees' hard work and achievements. Easily send personalized messages of recognition through chat apps, boosting morale and creating a positive workplace culture.",
        "Create a custom recognition program or choose from a range of templates.",
        "Boost morale and create a continuous feedback loop with 360 performance reviews.",
      ],
    },
  ];
  return (
    <>
      <div className="knotify-landing-page-wrap">
        <Navbar />

        <div className="knotify-landing-page">
          <div className="knotify-landing-page-content | hiring-knotify-landing-page-content">
            <span>KNOTIFY FOR ENGAGEMENT</span>
            <h1>
              Reveal workforce insights, prevent churn, without extra
              operational costs.
            </h1>
            <p>
              Elevate employee satisfaction, cut turnover via Knotify's
              chat-based engagement, surveys, pulse checks, and strategic
              improvement. Transform your workforce now.
            </p>
            <div className="buttons">
              {/* <button className="secondary">Product Tour</button> */}
              <button  onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}>Book your Demo !</button>
            </div>
          </div>
          <div className="knotify-landing-page-img">
            <img src={Illustrations.EngagementLanding} alt="" />
          </div>
        </div>
      </div>
      <div className="knotify-landing-page-bottom | hiring-landing-page-bottom">
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">90%</div>
          <p>
            REDUCTION IN MANUAL <br /> COMMUNICATION
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">4-5x</div>
          <p>
            HIGHER ENGAGEMENT THAN <br /> EMAIL OR SEPARATE APPS
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">22%</div>
          <p>
            INCREASE IN EMPLOYEE <br /> ENGAGEMENT
          </p>
        </div>
      </div>
      <div className="app-client-wrap">
        <div className="hiring-left-right">
          {data.map((item) => {
            return (
              <FlexCard
                img={item.img}
                header={item.header}
                points={item.points}
                subtitle={item.subtitle}
                numberPoints={false}
              />
            );
          })}
        </div>

        {/* <div className="clients">
          <h1>
            What our Clients are <br /> saying about us!
          </h1>
          <div className="carousel-container">
            <Carousel
              showThumbs={false}
              autoPlay={true}
              pauseOnHover={false}
              showStatus={false}
              infiniteLoop={true}
              dynamicHeight={false}
              showIndicators={false}
              showArrows={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <div
                  onClick={onClickHandler}
                  className="left-arrow  | carousel-arrow"
                  // className={hasPrev ? "left-arrow  | carousel-arrow active-arrow" : "left-arrow  | carousel-arrow"}
                >
                  <div className="left-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.LeftArrowBlack} alt="" />
                  </div>
                </div>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <div
                  onClick={onClickHandler}
                  // className={hasNext ? "right-arrow  | carousel-arrow active-arrow" : "right-arrow  | carousel-arrow"}
                  className="right-arrow  | carousel-arrow  active-arrow"
                >
                  <div className="right-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.RightArrowWhite} alt="" />
                  </div>
                </div>
              )}
            >
              <div className="carousel-card">
                <p>
                  While the company wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the team devoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  Teamollo delivered the site with inthe timeline as they
                  requested. Inthe end, the client found a 50% increase in
                  traffic with in days since its launch. They also had an
                  impressive ability to use technologies that the company hasn`t
                  used, which have also proved to be easy to use and reliable.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div> */}
      </div>

      <div className="faq-container">
        <h1>
          Still unsure? <br />
          Find the answers you’re looking for.
        </h1>
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default Index;
