import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
// import { useLocation, useNavigate } from "react-router-dom";
import Pages from "../pages";
import { getDataFromLocalstorage } from "../utils/locastorageEncryption";

// Array of route configurations
const routes = [
  {
    path: "/",
    exact: true,
    element: <Pages.Home />,
    private: false,
  },
  {
    path: "/hiring",
    exact: true,
    element: <Pages.Hiring />,
    private: false,
  },
  {
    path: "/announcements",
    exact: true,
    element: <Pages.Announcements />,
    private: false,
  },
  {
    path: "/engagement",
    exact: true,
    element: <Pages.Engagement />,
    private: false,
  },
  {
    path: "/training",
    exact: true,
    element: <Pages.Training />,
    private: false,
  },
  {
    path: "/knwoledgebase",
    exact: true,
    element: <Pages.KnowledgeBase />,
    private: false,
  },
  {
    path: "/pricing",
    exact: true,
    element: <Pages.Pricing />,
    private: false,
  },
  {
    path: "/privacy",
    exact: true,
    element: <Pages.Privacy />,
    private: false,
  },
  {
    path: "/tos",
    exact: true,
    element: <Pages.Terms />,
    private: false,
  },
];

export default function Navigation() {
  //   const location = useLocation();
  //   const navigate = useNavigate();
  const user = getDataFromLocalstorage("knotifyUser");
  //   const user = true;
  //   useEffect(() => {
  //     if (user && location.pathname === "/signin") {
  //       navigate("/dashboard");
  //     }

  //     if (!user && location.pathname === "/") {
  //       navigate("/signin");
  //     }
  //   }, [location, navigate, user]);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.private && !user ? (
              <Navigate to="/signin" />
            ) : (
              // Use PrivateRouteWithSidebar for all private routes except /create-game

              route.element
            )
          }
        />
      ))}
    </Routes>
  );
}
