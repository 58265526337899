import React, { useState, useEffect } from "react";
import Illustrations from "../../assets/Illustrations";
import "../../styles/Landing/landing.css";
import Navbar from "../../components/Navbar/Navbar";
import Icons from "../../assets/Icons";
import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EmployeJourney from "../../components/EmployeJourney";
const Index = () => {
  const tabs = [
    { id: "HIRING", label: "HIRING" },
    { id: "TRAINING", label: "TRAINING" },
    { id: "ENABLEMENT", label: "ENABLEMENT" },
    { id: "ENGAGEMENT", label: "ENGAGEMENT" },
  ];
  const [selectedFilter, setSelectedFilter] = useState(tabs[0].id);

  const totalTabs = tabs.length;
  let currentIndex = tabs.findIndex((tab) => tab.id === selectedFilter);

  useEffect(() => {
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % totalTabs;
      setSelectedFilter(tabs[currentIndex].id);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedFilter]);

  return (
    <>
      <div className="knotify-landing-page-wrap">
        <Navbar />

        <div className="knotify-landing-page">
          <div className="knotify-landing-page-content">
            <h1>Grow your Business through popular chat apps</h1>
            <p>
              Knotify is a powerful tool designed specifically for the deskless
              workforce. Our mission is to empower businesses to grow and thrive
              by unlocking the full potential of their mobile and remote teams.
            </p>
            <div className="buttons">
              {/* <button className="secondary">Product Tour</button> */}
              <button  onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}>Book your Demo !</button>
            </div>
          </div>
          <div className="knotify-landing-page-img">
            <img src={Illustrations.LandingImage} alt="" />
          </div>
        </div>
        <div className="knotify-landing-page-bottom">
          <div className="knotify-landing-page-bottom-item">
            <div className="knotify-landing-page-bottom-header">90%</div>
            <p>
              REDUCTION IN MANUAL <br /> COMMUNICATION
            </p>
          </div>
          <div className="knotify-landing-page-bottom-item">
            <div className="knotify-landing-page-bottom-header">4-5x</div>
            <p>
              HIGHER ENGAGEMENT THAN <br /> EMAIL OR SEPARATE APPS
            </p>
          </div>
          <div className="knotify-landing-page-bottom-item">
            <div className="knotify-landing-page-bottom-header">22%</div>
            <p>
              INCREASE IN EMPLOYEE <br /> ENGAGEMENT
            </p>
          </div>
        </div>
      </div>
      <div className="knotify-journey">
        <h1>
          We have you covered in you <br /> employee Journey
        </h1>
        <div className="knotify-journey-cards">
          <div className="knotify-journey-card-item | knotify-journey-cards-one">
            <div className="knotify-journey-card-item-pill">
              <img src={Icons.Edit} alt="" />
              Problem
            </div>
            <p>
              The challenge of managing a large, distributed frontline
              workforce.
            </p>
          </div>
          <img src={Icons.PlusFilled} alt="" />
          <div className="knotify-journey-card-item | knotify-journey-cards-two">
            <div className="knotify-journey-card-item-pill">
              <img src={Icons.Issue} alt="" />
              Issue
            </div>
            <p>
              Ineffective methods lead to failed communications such as unread
              emails, missed calls, and clunky workforce engagement apps.
            </p>
          </div>
          <img src={Icons.EqualFilled} alt="" />
          <div className="knotify-journey-card-item | knotify-journey-cards-three">
            <div className="knotify-journey-card-item-pill">
              <img src={Icons.Result} alt="" />
              Result
            </div>
            <p>This leads to unproductive, frustrated, and misaligned teams.</p>
          </div>
        </div>
        <div className="knotify-journey-sutitle">
          <p>
            <span> Lets face it:</span> Traditional methods of communication
            simply don't cut it in today's constantly evolving work landscape.
            Emails often go unread, phone calls get missed, and bulky workforce
            engagement apps are clunky and unappealing to employees. This
            results in a breakdown of communication, costing your business
            money, and leading to unproductive and frustrated teams.
          </p>
        </div>
        <div className="knotify-journey-cards-second">
          <div className="knotify-journey-cards-second-item">
            <img src={Icons.WarningRed} alt="" />
            <p>
              <span>Enterprise software </span>
              Get to yes faster by integrating contracts with CRM, so business
              users can self-serve on contracts.
            </p>
          </div>
          <div className="knotify-journey-cards-second-item">
            <img src={Icons.WarningRed} alt="" />
            <p>
              <span>Email </span>
              is inefficient and and prone to being left unread or filtered to
              junk. Stop bothering!
            </p>
          </div>
          <div className="knotify-journey-cards-second-item">
            <img src={Icons.WarningRed} alt="" />
            <p>
              <span>Whatsapp groups </span>
              are chaotic and leave you with zero insight into your workforce.
            </p>
          </div>
          <div className="knotify-journey-cards-second-item">
            <img src={Icons.WarningRed} alt="" />
            <p>
              <span>Separate mobile apps </span>
              are chaotic and leave you with zero insight into your workforce.
            </p>
          </div>
        </div>
      </div>

      <div
        className={`employee-journey ${
          selectedFilter === "HIRING"
            ? "hiring"
            : selectedFilter === "TRAINING"
            ? "training"
            : selectedFilter === "ENABLEMENT"
            ? "enablement"
            : selectedFilter === "ENGAGEMENT"
            ? "engagement"
            : null
        }`}
      >
        <h1>
          We have you covered in you <br /> employee Journey
        </h1>
        <div className="tabs-component">
          {tabs.map((tab, index) => {
            return (
              <div
                onClick={() => setSelectedFilter(tab.id)}
                key={index}
                className={
                  selectedFilter === tab.id ? "active-tab tab-item" : "tab-item"
                }
              >
                <div className="tab-item-pill">{tab.label}</div>
              </div>
            );
          })}
        </div>
        {selectedFilter === "HIRING" ? (
          <EmployeJourney.Hiring />
        ) : selectedFilter === "TRAINING" ? (
          <EmployeJourney.Training />
        ) : selectedFilter === "ENABLEMENT" ? (
          <EmployeJourney.Enablement />
        ) : selectedFilter === "ENGAGEMENT" ? (
          <EmployeJourney.Engagement />
        ) : null}
      </div>

      <div className="tools">
        <div className="tools-top">
          <div className="tools-top-left">
            <h1>
              90 of tools are built for traditional desk based employees{" "}
              <span> We are here to change that.</span>
            </h1>
          </div>
          <div className="tools-top-right">
            <p>
              Many businesses have tried to implement traditional desk-based
              tools, such as Microsoft Teams or Slack, to connect with their
              deskless workforce. However, these tools were not designed with
              the unique needs of frontline employees in mind.
            </p>
          </div>
        </div>
        <div className="tools-bottom">
          <div className="tools-bottom-left">
            <p>
              At Knotify, we understand that your frontline staff are constantly
              on the go and require a simple yet powerful solution to stay
              connected and productive. That's why our platform is optimized for
              mobile devices, integrates seamlessly with popular chat apps like
              WhatsApp, and uses AI to provide instant responses to employee
              inquiries.
            </p>
            <p>
              This ensures you get a great mobile UX, built for tech-savvy
              users, that comes at a fraction of the cost of other platforms and
              doesn't require a plethora of separate login details.
            </p>
            <p>
              We aim to bridge the communication gap between desk-based and
              frontline employees, creating a more cohesive and efficient
              workforce for your organization.
            </p>
          </div>
          <div className="tools-bottom-right">
            <img src={Illustrations.Tools} alt="" />
          </div>
        </div>
      </div>

      <div className="demo">
        <div className="demo-flex">
          <div className="demo-flex-left">
            <h1>
              Why use clunky software that wasn't built for deskless employees?
              Switch to Knotify today.
            </h1>
            {/* <p>
              Nunc scelerisque tincidunt elit. Vestibulum non mi ipsum. Cras
              pretium suscipit tellus sit amet aliquet. Vestibulum maximus
              lacinia massa non porttitor.
            </p> */}
            <div className="buttons">
              <button  onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}>Book your Demo !</button>
            </div>
          </div>
          <div className="demo-flex-right">
            <img src={Illustrations.DemoCard} alt="" />
          </div>
        </div>
        <div className="demo-subtitle">
          <p>
            Leverage the power of AI and the simplicity of chat. Connect with
            your teams on their preferred chat <br />
            apps like WhatsApp. Say goodbye to new software, extra logins, and
            hassle.
          </p>
        </div>

        <div className="demo-cards">
          <div className="demo-card">
            <img src={Icons.Demo1} alt="" />
            <p>
              35x higher response rates than email and other native apps (e.g.
              Meta Workplace).
            </p>
          </div>
          <div className="demo-card">
            <img src={Icons.Demo2} alt="" />
            <p>
              End to end encryption for secure conversations that never leak
              out.
            </p>
          </div>
          <div className="demo-card">
            <img src={Icons.Demo3} alt="" />
            <p>
              Rich third party integrations with your existing tech stack. No
              added overhead.
            </p>
          </div>
          <div className="demo-card">
            <img src={Icons.Demo4} alt="" />
            <p>
              Quick adoption through chat apps your employees already use and
              love.
            </p>
          </div>
        </div>
        <div className="buttons | demo-buttom">
          <button  onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}>Book your Demo !</button>
        </div>
      </div>

      <div className="app-client-wrap">
        <div className="apps">
          <h1>
            Eliminate the headache of multiple <br /> apps and logins.
          </h1>
          <img src={Illustrations.Apps} alt="" />
          <img
            src={Illustrations.AppsMobile}
            alt=""
            className="mobile-apps-img"
          />
        </div>
        {/* <div className="clients">
          <h1>
            What our Clients are <br /> saying about us!
          </h1>
          <div className="carousel-container">
            <Carousel
              showThumbs={false}
              autoPlay={true}
              pauseOnHover={false}
              showStatus={false}
              infiniteLoop={true}
              dynamicHeight={false}
              showIndicators={false}
              showArrows={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <div
                  onClick={onClickHandler}
                  className="left-arrow  | carousel-arrow"
                  // className={hasPrev ? "left-arrow  | carousel-arrow active-arrow" : "left-arrow  | carousel-arrow"}
                >
                  <div className="left-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.LeftArrowBlack} alt="" />
                  </div>
                </div>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <div
                  onClick={onClickHandler}
                  // className={hasNext ? "right-arrow  | carousel-arrow active-arrow" : "right-arrow  | carousel-arrow"}
                  className="right-arrow  | carousel-arrow  active-arrow"
                >
                  <div className="right-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.RightArrowWhite} alt="" />
                  </div>
                </div>
              )}
            >
              <div className="carousel-card">
                <p>
                  While the company wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the team devoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  Teamollo delivered the site with inthe timeline as they
                  requested. Inthe end, the client found a 50% increase in
                  traffic with in days since its launch. They also had an
                  impressive ability to use technologies that the company hasn`t
                  used, which have also proved to be easy to use and reliable.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div> */}
      </div>

      <div className="faq-container">
        <h1>
          Still unsure? <br />
          Find the answers you’re looking for.
        </h1>
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default Index;
