import React from "react";
import "./footer.css";
import Brand from "../../assets/Brand";
import Icons from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer-left">
        <img src={Brand.Logo} alt="" />
        <div className="footer-left-bottom">
          <div className="footer-left-bottom-icons">
            <img
              onClick={() => {
                window.open("https://www.linkedin.com/company/knotify/");
              }}
              src={Icons.LinkeDin}
              alt=""
            />
            <img
              onClick={() => {
                window.open("https://x.com/Knotifyapp");
              }}
              src={Icons.Twitter}
              alt=""
            />
            <img
              onClick={() => {
                window.open("https://www.youtube.com/@Knotifyio");
              }}
              src={Icons.Youtue}
              alt=""
            />
          </div>

          <div className="footer-left-bottom-copyright">
            © 2023, Knotify. All rights reserved.
          </div>
        </div>
      </div>
      <div className="footer-right">
        {/* <div className="footer-right-item">
          <div className="footer-right-item-content">
            <div className="footer-right-item-header">Compare</div>
            <div className="footer-right-item-list">
              <p>vs World Manager</p>
              <p>vs WATI</p>
              <p> vs Rasayel</p>
              <p>vs Workplace</p>
            </div>
          </div>
        </div> */}
        <div className="footer-right-item">
          <div className="footer-right-item-content">
            <div className="footer-right-item-header">Use Cases</div>
            <div className="footer-right-item-list">
              <p
                onClick={() => {
                  navigate("/hiring");
                  window.scrollTo(0, 0);
                }}
              >
                Hiring
              </p>
              <p
                onClick={() => {
                  navigate("/training");
                  window.scrollTo(0, 0);
                }}
              >
                Training
              </p>
              <p
                onClick={() => {
                  navigate("/announcements");
                  window.scrollTo(0, 0);
                }}
              >
                Announcements
              </p>
              <p
                onClick={() => {
                  navigate("/engagement");
                  window.scrollTo(0, 0);
                }}
              >
                Engagement{" "}
              </p>
              <p
                onClick={() => {
                  navigate("/knowledgebase");
                  window.scrollTo(0, 0);
                }}
              >
                Knowledge Base
              </p>
            </div>
          </div>
        </div>

        <div className="footer-right-item">
          <div className="footer-right-item-content">
            <div className="footer-right-item-header">Resources</div>
            <div className="footer-right-item-list">
              <p>Blogs </p>
              <p>Case studies</p>
            </div>
          </div>
        </div>
        <div className="footer-right-item">
          <div className="footer-right-item-content">
            <div className="footer-right-item-header">Company</div>
            <div className="footer-right-item-list">
              <p>Contact us</p>

              <p
                onClick={() => {
                  navigate("/privacy");
                  window.scrollTo(0, 0);
                }}
              >
                Privacy Policy{" "}
              </p>
              <p
                onClick={() => {
                  navigate("/tos");
                  window.scrollTo(0, 0);
                }}
              >
                Terms of Service
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-left-bottom-mobile">
        <div className="footer-left-bottom-icons">
          <img
            onClick={() => {
              window.open("https://www.linkedin.com/company/knotify/");
            }}
            src={Icons.LinkeDin}
            alt=""
          />
          <img
            onClick={() => {
              window.open("https://x.com/Knotifyapp");
            }}
            src={Icons.Twitter}
            alt=""
          />
          <img
            onClick={() => {
              window.open("https://www.youtube.com/@Knotifyio");
            }}
            src={Icons.Youtue}
            alt=""
          />
        </div>
        <div className="footer-left-bottom-copyright">
          © 2023, Knotify. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
