import React from "react";
import Illustrations from "../../assets/Illustrations";
import Navbar from "../../components/Navbar/Navbar";
import FlexCard from "../../components/FlexCard/FlexCard";
import "./hiring.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Icons from "../../assets/Icons";
import Footer from "../../components/Footer/Footer";
import Faq from "../../components/Faq/Faq";

const Index = () => {
  const data = [
    {
      img: Illustrations.Hiring1,
      header:
        "Save hundreds of hours through automation of the initial HR process.",
      points: [
        "Knotify users save over 30 minutes per candidate thanks to the automation available on the platform.",
        "From booking, rearranging, and canceling interviews to sending offer letters and gathering employee onboarding documents, Knotify takes care of all the tedious tasks for you.",
        "Create FAQs that allow candidates to resolve questions without interaction with your busy talent acquisition team.",
        "Workflows can be created in minutes with an intuitive interface.",
      ],
      subtitle: "AUTOMATED WORKFLOWS",
    },
    {
      img: Illustrations.Hiring2,
      header: "Reduce your no-show rate by 50% with automated reminders.",
      points: [
        "By automating the process of booking and rearranging interviews, no-show rates can be cut in half. Why waste time and money on candidates that don't show up?",
        "Deliver vital information, such as the physical address of your business, in a timely manner, helping to avoid overwhelming applicants.",
        "Keep candidates engaged with updates on their applications, helping them remain interested in the role and preventing losing them to competitors.",
      ],
      subtitle: "NO SHOW REMINDERS",
    },
    {
      img: Illustrations.Hiring3,
      subtitle: "CANDIDATE EXPERIENCE",
      header:
        "In a competitive labor market, it's important to stand out with a world-class candidate experience.",
      points: [
        "Applicants are interviewing you as much as you are interviewing them. Demonstrate your commitment to efficiency and professionalism with the streamlined hiring process that offers a great first impression.",
        "Modern applicants expect contact through emails, messages, and apps. Businesses that continue to rely on phone calls will fall behind the pack. Reach your candidate before anyone else does!",
      ],
    },
    {
      img: Illustrations.Hiring4,
      subtitle: "ONBOARDING",
      header: "The automation process doesn't stop at applications.",
      points: [
        "Deliver training material in advance so your team member walks in with the knowledge to hit the ground running.",
        "Collect legally necessary documentation and payroll information before their first day of employment.",
        "Deliver up-to-date information to new joiners to keep up with rapidly evolving business needs.",
      ],
    },
  ];
  return (
    <>
      <div className="knotify-landing-page-wrap">
        <Navbar />

        <div className="knotify-landing-page">
          <div className="knotify-landing-page-content | hiring-knotify-landing-page-content">
            <span>KNOTIFY FOR HIRING</span>
            <h1>
              Effortlessly go from application to first workday with automation.
            </h1>
            <p>
              This streamlined process presents your business in a professional
              and efficient light, helping you attract top talent and retain
              them for the long term while reducing the workload of your HR
              team.
            </p>
            <div className="buttons">
              {/* <button className="secondary">Product Tour</button> */}
              <button
                onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}
              >
                Book your Demo !
              </button>
            </div>
          </div>
          <div className="knotify-landing-page-img">
            <img src={Illustrations.HiringLanding} alt="" />
          </div>
        </div>
      </div>
      <div className="knotify-landing-page-bottom | hiring-landing-page-bottom">
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">90%</div>
          <p>
            REDUCTION IN MANUAL <br /> COMMUNICATION
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">4-5x</div>
          <p>
            HIGHER ENGAGEMENT THAN <br /> EMAIL OR SEPARATE APPS
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">22%</div>
          <p>
            INCREASE IN EMPLOYEE <br /> ENGAGEMENT
          </p>
        </div>
      </div>
      <div className="app-client-wrap">
        <div className="hiring-left-right">
          {data.map((item) => {
            return (
              <FlexCard
                img={item.img}
                header={item.header}
                points={item.points}
                subtitle={item.subtitle}
                numberPoints={false}
              />
            );
          })}
        </div>

        {/* <div className="clients">
          <h1>
            What our Clients are <br /> saying about us!
          </h1>
          <div className="carousel-container">
            <Carousel
              showThumbs={false}
              autoPlay={true}
              pauseOnHover={false}
              showStatus={false}
              infiniteLoop={true}
              dynamicHeight={false}
              showIndicators={false}
              showArrows={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <div
                  onClick={onClickHandler}
                  className="left-arrow  | carousel-arrow"
                  // className={hasPrev ? "left-arrow  | carousel-arrow active-arrow" : "left-arrow  | carousel-arrow"}
                >
                  <div className="left-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.LeftArrowBlack} alt="" />
                  </div>
                </div>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <div
                  onClick={onClickHandler}
                  // className={hasNext ? "right-arrow  | carousel-arrow active-arrow" : "right-arrow  | carousel-arrow"}
                  className="right-arrow  | carousel-arrow  active-arrow"
                >
                  <div className="right-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.RightArrowWhite} alt="" />
                  </div>
                </div>
              )}
            >
              <div className="carousel-card">
                <p>
                  While the company wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the team devoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  Teamollo delivered the site with inthe timeline as they
                  requested. Inthe end, the client found a 50% increase in
                  traffic with in days since its launch. They also had an
                  impressive ability to use technologies that the company hasn`t
                  used, which have also proved to be easy to use and reliable.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div> */}
      </div>
      <div className="implementation-flex | hiring-left-right">
        <FlexCard
          img={Illustrations.Implementation}
          header="With our easy-to-create processes, you'll be able to implement Knotify in under 10 days."
          points={[
            "Login to Knotify.",
            "Choose from Onboard, Train, Engage, Develop, or Recognise programs.",
            "Select a ready-to-go template or create your own module.",
            "Click and build out complex workflows in minutes.",
            "Add relevant content like text, gif, videos, questions, and more.",
            "Run the program! It’s that easy.",
          ]}
          numberPoints={true}
          subtitle="IMPLEMENTATION"
        />
      </div>
      <div className="faq-container">
        <h1>
          Still unsure? <br />
          Find the answers you’re looking for.
        </h1>
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default Index;
