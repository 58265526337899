import React from "react";
import Icons from "../../assets/Icons";
import { useNavigate } from "react-router-dom";

const NavbarDropdown = () => {
  const navigate = useNavigate();
  return (
    <div className="navbar-dropdown">
      <div className="navbar-dropdown-list">
        <div
          onClick={() => navigate("/hiring")}
          className="navbar-dropdown-list-item"
        >
          <img src={Icons.Hiring} alt="" />
          <div className="navbar-dropdown-list-content">
            <h1>Hiring</h1>
            <p>Automate hiring from application to day 1.</p>
          </div>
        </div>

        <div
          onClick={() => navigate("/training")}
          className="navbar-dropdown-list-item"
        >
          <img src={Icons.Training} alt="" />
          <div className="navbar-dropdown-list-content">
            <h1>Training</h1>
            <p>Ensure your frontline knows how to get the job done.</p>
          </div>
        </div>
        <div
          onClick={() => navigate("/announcements")}
          className="navbar-dropdown-list-item"
        >
          <img src={Icons.Announcement} alt="" />
          <div className="navbar-dropdown-list-content">
            <h1>Announcements</h1>
            <p>Reach your frontline with confidence every time.</p>
          </div>
        </div>
        <div
          onClick={() => navigate("/engagement")}
          className="navbar-dropdown-list-item"
        >
          <img src={Icons.Engagemet} alt="" />
          <div className="navbar-dropdown-list-content">
            <h1>Engagement</h1>
            <p>Collect actionable workforce insights and reduce churn.</p>
          </div>
        </div>
        <div
          onClick={() => navigate("/knwoledgebase")}
          className="navbar-dropdown-list-item"
        >
          <img src={Icons.KnowLedgeBase} alt="" />
          <div className="navbar-dropdown-list-content">
            <h1>Knowledge Base</h1>
            <p>Reduce errors and overhead through knowledge self serve</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarDropdown;
