import React from "react";
import Icons from "../../assets/Icons";

const PricingCards = () => {
  const plans = [
    {
      name: "Personal plan",
      desc: "Get a professional website designed according to your needs.",
      price: "0",
      type: "normal",
      features: [
        {
          name: "2 Admins ",
          type: "green",
        },
        {
          name: "3rd party Integrations",
          type: "green",
        },
        {
          name: "2 Webhooks",
          type: "green",
        },
        {
          name: "Analytics Basic",
          type: "green",
        },
        {
          name: "Analytics Basic",
          type: "green",
        },
        {
          name: "5 GB File Storage",
          type: "green",
        },
        {
          name: "Audit Log",
          type: "grey",
        },
        {
          name: "SSO",
          type: "grey",
        },
        {
          name: " Basic Support",
          type: "green",
        },
        {
          name: "Dedicated Account Manager 500 users",
          type: "grey",
        },
      ],
    },
    {
      name: "Starter plan",
      desc: "Get a professional website designed according to your needs.",
      price: 99,
      type: "popular",
      features: [
        {
          name: "5 Admins ",
          type: "green",
        },
        {
          name: "3rd party Integrations",
          type: "green",
        },
        {
          name: "5 Webhooks",
          type: "green",
        },
        {
          name: "5 Custom Templates ",
          type: "green",
        },
        {
          name: "Analytics Advanced",
          type: "green",
        },
        {
          name: "50 GB File Storage",
          type: "green",
        },
        {
          name: "Audit Log",
          type: "grey",
        },
        {
          name: "SSO",
          type: "grey",
        },
        {
          name: "  Priority Support",
          type: "green",
        },
        {
          name: "Dedicated Account Manager 500 users",
          type: "grey",
        },
      ],
    },
    {
      name: "Professional plan",
      desc: "Get a professional website designed according to your needs.",
      price: 299,
      type: "normal",
      features: [
        {
          name: "Unlimited Admins",
          type: "green",
        },
        {
          name: "Unlimited 3rd party Integrations",
          type: "green",
        },
        {
          name: "Unlimited Webhooks",
          type: "green",
        },
        {
          name: "Custom Templates Unlimited",
          type: "green",
        },
        {
          name: "Analytics Custom",
          type: "green",
        },
        {
          name: "Unlimited File Storage",
          type: "green",
        },
        {
          name: "Audit Log",
          type: "green",
        },
        {
          name: "SSO",
          type: "green",
        },
        {
          name: "  Dedicated  Support",
          type: "green",
        },
        {
          name: "Dedicated Account Manager 500 users",
          type: "green",
        },
      ],
    },
    {
      name: "Enterprise plan",
      desc: "Get a professional website designed according to your needs.",
      price: null,
      type: "normal",
      button: "Contact Us",
      features: [
        {
          name: "Unlimited Admins",
          type: "green",
        },
        {
          name: "Unlimited 3rd party Integrations",
          type: "green",
        },
        {
          name: "Unlimited Webhooks",
          type: "green",
        },
        {
          name: "Custom Templates Unlimited",
          type: "green",
        },
        {
          name: "Analytics Custom",
          type: "green",
        },
        {
          name: "Unlimited File Storage",
          type: "green",
        },
        {
          name: "Audit Log",
          type: "green",
        },
        {
          name: "SSO",
          type: "green",
        },
        {
          name: "  Dedicated  Support",
          type: "green",
        },
        {
          name: "Dedicated Account Manager 500 users",
          type: "green",
        },
      ],
    },
  ];
  return (
    <div className="pricing-plans">
      {plans.map((plan, index) => {
        return (
          <div key={index} className="pricing-plan">
            <div
              className={
                plan.type === "popular"
                  ? "pricing-plan-card popular"
                  : "pricing-plan-card"
              }
            >
              {plan.type === "popular" && (
                <div className="most-popular-tag">Most Popular</div>
              )}
              <div className="pricing-plan-card-info">
                <h1>{plan.name}</h1>
                <p>{plan.desc}</p>
              </div>
              {plan.price && (
                <div className="pricing-plan-price">
                  <p> Starting from</p>
                  <div className="price-value">
                    <span>$</span>
                    {plan.price}
                  </div>
                  <span> per month</span>
                </div>
              )}
              {plan.button ? (
                <button>{plan.button}</button>
              ) : (
                <button>Start Free Trial</button>
              )}
            </div>
            <div className="pricing-plan-features">
              <h1>{plan.name} Features</h1>
              {plan.features.map((feature, index) => {
                return (
                  <div className="pricing-plan-feature">
                    <img
                      src={
                        feature.type === "green"
                          ? Icons.GreenTick
                          : Icons.GreyTick
                      }
                      alt=""
                    />
                    <div
                      style={
                        feature.type === "green"
                          ? { color: "#000000" }
                          : { color: "#949A9A" }
                      }
                      className="pricing-plan-feature-name"
                    >
                      {feature.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricingCards;
