import Hiring from "./Hiring.js";
import Enablement from "./Enablement.js";
import Engagement from "./Engagement.js";
import Training from "./Training.js";

const EmployeJourney = {
  Hiring,
  Enablement,
  Engagement,
  Training,
};

export default EmployeJourney;
