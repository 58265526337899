import CryptoJS from "crypto-js";
const ENCRYPTION_KEY = "ad3d2d623bf626dba95876b043ddf3e1";
const IV = "b27c58b15267dd944989b75ec6a28d02";
export const saveDataToLocalstorage = (key, data) => {
  try {
    const jsonData = JSON.stringify(data);
    const ciphertext = CryptoJS.AES.encrypt(jsonData, ENCRYPTION_KEY, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    localStorage.setItem(key, ciphertext);
  } catch (error) {
    console.error(`Failed to encrypt and save data: ${error.message}`);
  }
};

export const getDataFromLocalstorage = (key) => {
  try {
    const ciphertext = localStorage.getItem(key);
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY, {
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
    return null;
  } catch (error) {
    console.error(`Failed to decrypt data: ${error.message}`);
    return null;
  }
};
