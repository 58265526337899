import LandingImage from "./LandingImage.webp";
import HiringBg from "./HiringBg.webp";
import EnablementBg from "./EnablementBg.webp";
import EngagementBg from "./EngagementBg.webp";
import TrainingBg from "./TrainingBg.webp";
import Experience from "./Experience.webp";
import Tools from "./Tools.webp";
import Apps from "./Apps.svg";
import FooterBg from "./FooterBg.webp";
import Demo from "./Demo.webp";
import DemoCard from "./DemoCard.webp";
import UserIcon from "./UserIcon.png";
import AppsMobile from "./AppsMobile.svg";
import MenuBg from "./MenuBg.png";
import Training from "./Training.webp";
import Enablement from "./Enablement.webp";
import Engagement from "./Engagement.webp";
import HiringLanding from "./HiringLanding.webp";
import Hiring1 from "./Hiring1.webp";
import Hiring2 from "./Hiring2.webp";
import Hiring3 from "./Hiring3.webp";
import Hiring4 from "./Hiring4.webp";
import Announcements1 from "./Announcements1.webp";
import Announcements2 from "./Announcements2.webp";
import Announcements3 from "./Announcements3.webp";
import Announcements4 from "./Announcements4.webp";
import Training1 from "./Training1.webp";
import Training2 from "./Training2.webp";
import Training3 from "./Training3.webp";
import Training4 from "./Training4.webp";
import KnowledgeBase5 from "./KnowledgeBase5.webp";
import Implementation from "./Implementation.webp";
import AnnouncementsLanding from "./AnnouncementsLanding.webp";
import EngagementLanding from "./EngagementLanding.webp";
import KnowledgeBaseLanding from "./KnowledgeBaseLanding.webp";

// Export the SVG icons as an object
const Illustrations = {
  Hiring1,
  Hiring2,
  Hiring3,
  Hiring4,
  Announcements1,
  Announcements2,
  Announcements3,
  Announcements4,
  Training1,
  Training2,
  Training3,
  Training4,
  KnowledgeBase5,
  KnowledgeBaseLanding,
  AnnouncementsLanding,
  EngagementLanding,
  Implementation,
  LandingImage,
  HiringBg,
  EnablementBg,
  EngagementBg,
  TrainingBg,
  FooterBg,
  UserIcon,
  Demo,
  HiringLanding,
  Tools,
  Engagement,
  Enablement,
  DemoCard,
  MenuBg,
  Apps,
  AppsMobile,
  Training,
  Experience,
};

export default Illustrations;
