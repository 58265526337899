import React from "react";
import "../../styles/Pricing/pricing.css";
import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Icons from "../../assets/Icons";
import PricingCards from "../../components/PricingCards/PricingCards";

const Index = () => {
  const before = [
    "You’re spending hundreds of hours on manual comms.",
    "Your frontline staff cannot find the right info at the right time.",
    "You have limited insights on employee satisfaction and churn.",
    "You’re spending $$$ on engagement platforms but not seeing results.",
    "Your employees are not actively using desktop optimized tools with email log ins.",
    "Inadequate training and lack of feedback are hurting employee engagement.",
  ];
  const after = [
    "Automated, chat based workflows to save time and effort.",
    "Increased engagement via chat apps your staff use everyday.",
    "AI powered knowledge base to free up managers and reduce delays.",
    "Structured, prioritized communication for zero cognitive overload.",
    "Real time, actionable insights sourced straight from your employees.",
    "Reduced turnover and overhead costs with chat based employee enablement.",
  ];
  return (
    <>
      <div className="knotify-landing-page-wrap">
        <Navbar />

        <div className="knotify-landing-page | pricing-page">
          <div className="knotify-landing-page-content">
            <h1>
              Great software for deskless teams doesn’t have to be expensive.
            </h1>
            <p>
              Cut down overhead costs and ditch clunky software. Get a flexible,
              purpose-built tool to onboard, train, and engage your staff - at
              an affordable price.
            </p>
          </div>
        </div>
      </div>

      <PricingCards/>

      <div className="before-after-container">
        <h1>
          Stop wasting time, money, and nerves on <br /> overhead and expensive
          tools that don’t work.
        </h1>
        <div className="before-after-flex">
          <div className="before | before-after-flex-card">
            <p>Before Knotify</p>

            <div className="before-after-flex-card-cards">
              {before.map((item) => (
                <div className="before-after-flex-card-card">{item}</div>
              ))}
            </div>
          </div>
          <div className="before-after-logo">
            <img src={Icons.BeforeAfter} alt="" />
          </div>
          <div className="after | before-after-flex-card">
            <p>After Knotify</p>
            <div className="before-after-flex-card-cards">
              {after.map((item) => (
                <div className="before-after-flex-card-card">{item}</div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pricing-grid">
        <h1>
          Adopting new tech is daunting. That’s why, Knotify <br /> is built to
          be as easy as your favorite chat app.
        </h1>
        <div className="pricing-grid-card-container">
          <div className="pricing-grid-card-wrap">
            <div className="pricing-grid-card-inner-wrap">
              <div className="pricing-grid-card">
                <img src={Icons.Pricing1} alt="" />
                <p>
                  Centralized user management to manage all employee chat
                  preferences.
                </p>
              </div>
              <div className="pricing-grid-card">
                <img src={Icons.Pricing2} alt="" />
                <p>Automate entire staff lifecycle easily.</p>
              </div>
              <div className="pricing-grid-card">
                <img src={Icons.Pricing3} alt="" />
                <p>Implementation in a few hours.</p>
              </div>
            </div>
            <div className="pricing-grid-card-inner-wrap">
              <div className="pricing-grid-card">
                <img src={Icons.Pricing4} alt="" />
                <p>No additional app or email login required.</p>
              </div>
              <div className="pricing-grid-card">
                <img src={Icons.Pricing5} alt="" />
                <p>
                  Super quick setup: Run your programs in seconds with templates
                  and rich integrations.
                </p>
              </div>
            </div>
          </div>
          <div className="pricing-grid-card-wrap">
            <div className="pricing-grid-card">
              <img src={Icons.Pricing6} alt="" />
              <p>
                Single integration with employer’s chat account instead of
                individual integration.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-container | pricing-faq">
        <h1>
          Still unsure? <br />
          Find the answers you’re looking for.
        </h1>
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default Index;
