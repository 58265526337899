// Import all SVG icons
import Announcement from "./Announcement.svg";
import Cross from "./Cross.svg";
import DownArrow from "./DownArrow.svg";
import Engagemet from "./Engagemet.svg";
import Hiring from "./Hiring.svg";
import KnowLedgeBase from "./KnowLedgeBase.svg";
import KnowtifySymbol from "./KnowtifySymbol.svg";
import Menu from "./Menu.svg";
import Plus from "./Plus.svg";
import Star from "./Star.svg";
import Training from "./Training.svg";
import PlusFilled from "./PlusFilled.svg";
import EqualFilled from "./EqualFilled.svg";
import Result from "./Result.svg";
import Issue from "./Issue.svg";
import Edit from "./Edit.svg";
import WarningWhite from "./WarningWhite.svg";
import WarningRed from "./WarningRed.svg";
import Demo1 from "./Demo1.svg";
import Demo2 from "./Demo2.svg";
import Demo3 from "./Demo3.svg";
import Demo4 from "./Demo4.svg";
import RightArrowBlack from "./RightArrowBlack.svg";
import RightArrowWhite from "./RightArrowWhite.svg";
import LeftArrowBlack from "./LeftArrowBlack.svg";
import LeftArrowWhite from "./LeftArrowWhite.svg";
import LinkeDin from "./LinkeDin.svg";
import Twitter from "./Twitter.svg";
import Youtue from "./Youtue.svg";
import Minus from "./Minus.svg";
import Tick from "./Tick.svg";
import BeforeAfter from "./BeforeAfter.svg";
import Pricing1 from "./Pricing1.svg";
import Pricing2 from "./Pricing2.svg";
import Pricing3 from "./Pricing3.svg";
import Pricing4 from "./Pricing4.svg";
import Pricing5 from "./Pricing5.svg";
import Pricing6 from "./Pricing6.svg";
import GreenTick from "./GreenTick.svg";
import GreyTick from "./GreyTick.svg";

// Export the SVG icons as an object
const Icons = {
  Announcement,
  GreenTick,
  GreyTick,
  Cross,
  Demo1,
  Demo2,
  Demo3,
  Demo4,
  Pricing1,
  Pricing2,
  Pricing3,
  Pricing4,
  Pricing5,
  Pricing6,
  RightArrowBlack,
  RightArrowWhite,
  Youtue,
  BeforeAfter,
  LeftArrowBlack,
  Minus,
  Twitter,
  LinkeDin,
  LeftArrowWhite,
  WarningWhite,
  WarningRed,
  EqualFilled,
  Issue,
  Edit,
  PlusFilled,
  Result,
  DownArrow,
  Engagemet,
  Hiring,
  KnowLedgeBase,
  KnowtifySymbol,
  Menu,
  Plus,
  Star,
  Training,
  Tick,
};

export default Icons;
