import React from "react";
import Icons from "../../assets/Icons";

const FlexCard = ({ img, header, points, subtitle, numberPoints }) => {
  return (
    <div className="flex-card">
      <div className="flex-content">
        <span>{subtitle}</span>
        <h1>{header}</h1>
        {numberPoints ? (
          <>
            <div className="number-points">
              {points.map((point, index) => (
                <p key={index}>
                  <div className="number">{index + 1}</div>
                  {point}
                </p>
              ))}
              <div className="numer-border"></div>
            </div>
          </>
        ) : (
          <div className="points">
            {points.map((point) => (
              <p>
                {" "}
                <img src={Icons.Tick} alt="" /> {point}
              </p>
            ))}
          </div>
        )}

        <button
          onClick={() => {
            window.open("https://tally.so/r/3197GO");
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#e3da28"; // Change background color on hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#f9f038"; // Restore default background color on hover out
          }}
          onMouseDown={(e) => {
            e.target.style.backgroundColor = "#bab328"; // Change background color on click
          }}
          onMouseUp={(e) => {
            e.target.style.backgroundColor = "#e3da28"; // Restore default background color on release
          }}
        >
          Book your Demo !
        </button>
      </div>
      <div className="flex-img">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default FlexCard;
