import React from "react";
import Illustrations from "../../assets/Illustrations";

const Training = () => {
  return (
    <div className="employee-journey-flex">
      <div className="employee-journey-flex-left">
        <img src={Illustrations.Training} alt="" />
      </div>
      <div className="employee-journey-flex-right">
        <h1>Don't allocate set time for training - let your employees learn and develop as they work.</h1>
        <p>
        Streamline compliance and SOP retention with our efficient, prompt-based chat programs.
        </p>
        <p>
        Transform learning into an ongoing and memorable journey with our interactive, micro-learning programs
        </p>

        <p>
        Generate training documentation in mere minutes utilizing our proven and effective templates.
        </p>
      </div>
    </div>
  );
};

export default Training;
