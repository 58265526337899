import React from "react";
import Illustrations from "../../assets/Illustrations";
import Navbar from "../../components/Navbar/Navbar";
import FlexCard from "../../components/FlexCard/FlexCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Icons from "../../assets/Icons";
import Footer from "../../components/Footer/Footer";
import Faq from "../../components/Faq/Faq";

const Index = () => {
  const data = [
    {
      img: Illustrations.Training1,
      header:
        "Maximize engagement with micro-learning and empower your deskless workers to reach their full potential.",
      points: [
        "Traditional training methods and existing LMS often fail to engage your deskless teams effectively.",
        "Whether it's a straightforward product update or a complex, multi-step compliance checklist, Knotify has got you covered.",
        "Craft an interactive learning experience using a mix of text, images, videos, embedded documents, and quizzes.",
      ],
      subtitle: "MICRO-LEARNING",
    },
    {
      img: Illustrations.Training2,
      header:
        "Farewell compliance worries, embrace automated solutions, and enhance efficiency.",
      points: [
        "Set up weekly, monthly, or quarterly recurrences for your most critical compliance training sessions.",
        "Our automated reminders work tirelessly to ensure maximum completion in a matter of days, not weeks..",
        "Stay informed with alerts whenever your workforce falls below the minimum completion thresholds.",
      ],
      subtitle: "COMPLIANCE",
    },
    {
      img: Illustrations.Training3,
      subtitle: "RICH ANALYTICS",
      header:
        "Stay informed with detailed analytics on your learning and development.",
      points: [
        "Gain immediate insights into completion rates, responses, average scores, and time-to-complete metrics.",
        "Act precisely with our filters and alerts designed to pinpoint low performers, allowing for targeted interventions.",
        "Enjoy the freedom to conduct your own analysis by bulk exporting your data.",
      ],
    },
    {
      img: Illustrations.Training4,
      subtitle: "ENGAGEMENT",
      header: "See higher engagement than any other avenue of communication.",
      points: [
        "You want the best and brightest applicants. To achieve this, you need to move with the times and approach them in the same way that they approach the world - through technology.",
        "Cut out the app downloads, email log-ins, and phone calls. Why spend money developing expensive hiring tools that don't work when you can achieve 4-5 times higher engagement by communicating with applicants where they already are?",
        "With Knotify, you can communicate with candidates through their preferred method - messages on platforms such as WhatsApp.",
        "Structure and automate chat with Knotify. Bridge the disconnect.",
      ],
    },
  ];
  return (
    <>
      <div className="knotify-landing-page-wrap">
        <Navbar />

        <div className="knotify-landing-page">
          <div className="knotify-landing-page-content | hiring-knotify-landing-page-content">
            <span>KNOTIFY FOR TRAINING</span>
            <h1>Empower employees to learn and grow while they work.</h1>
            <p>
              Thanks to Knotify's chat-based continuous learning features, your
              employees can get access to bite-sized training content on any
              device and at any time that ensures a highly trained and compliant
              deskless workforce.
            </p>
            <div className="buttons">
              {/* <button className="secondary">Product Tour</button> */}
              <button
                onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}
              >
                Book your Demo !
              </button>
            </div>
          </div>
          <div className="knotify-landing-page-img">
            <img src={Illustrations.EngagementLanding} alt="" />
          </div>
        </div>
      </div>
      <div className="knotify-landing-page-bottom | hiring-landing-page-bottom">
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">90%</div>
          <p>
            REDUCTION IN MANUAL <br /> COMMUNICATION
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">4-5x</div>
          <p>
            HIGHER ENGAGEMENT THAN <br /> EMAIL OR SEPARATE APPS
          </p>
        </div>
        <div className="knotify-landing-page-bottom-item">
          <div className="knotify-landing-page-bottom-header">22%</div>
          <p>
            INCREASE IN EMPLOYEE <br /> ENGAGEMENT
          </p>
        </div>
      </div>
      <div className="app-client-wrap">
        <div className="hiring-left-right">
          {data.map((item) => {
            return (
              <FlexCard
                img={item.img}
                header={item.header}
                points={item.points}
                subtitle={item.subtitle}
                numberPoints={false}
              />
            );
          })}
        </div>

        {/* <div className="clients">
          <h1>
            What our Clients are <br /> saying about us!
          </h1>
          <div className="carousel-container">
            <Carousel
              showThumbs={false}
              autoPlay={true}
              pauseOnHover={false}
              showStatus={false}
              infiniteLoop={true}
              dynamicHeight={false}
              showIndicators={false}
              showArrows={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <div
                  onClick={onClickHandler}
                  className="left-arrow  | carousel-arrow"
                  // className={hasPrev ? "left-arrow  | carousel-arrow active-arrow" : "left-arrow  | carousel-arrow"}
                >
                  <div className="left-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.LeftArrowBlack} alt="" />
                  </div>
                </div>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <div
                  onClick={onClickHandler}
                  // className={hasNext ? "right-arrow  | carousel-arrow active-arrow" : "right-arrow  | carousel-arrow"}
                  className="right-arrow  | carousel-arrow  active-arrow"
                >
                  <div className="right-arrow-icon | carousel-arrow-icon">
                    <img src={Icons.RightArrowWhite} alt="" />
                  </div>
                </div>
              )}
            >
              <div className="carousel-card">
                <p>
                  While the company wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the team devoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  Teamollo delivered the site with inthe timeline as they
                  requested. Inthe end, the client found a 50% increase in
                  traffic with in days since its launch. They also had an
                  impressive ability to use technologies that the company hasn`t
                  used, which have also proved to be easy to use and reliable.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-card">
                <p>
                  While the copany wishes they had more time to work out the
                  kinks with the Teamollo, they are quite happy with the result
                  of the project. The resulting website that the teamdevoloped
                  is fast and the communication with the vender was very good.
                  The company will work with them again.
                </p>
                <div className="carousel-card-user">
                  <div className="user-icon">
                    <img src={Illustrations.UserIcon} alt="" />
                  </div>
                  <div className="user-review">
                    <div className="user-name">Ashley Cooper</div>
                    <div className="stars">
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                      <img src={Icons.Star} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div> */}
      </div>

      <div className="faq-container">
        <h1>
          Still unsure? <br />
          Find the answers you’re looking for.
        </h1>
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default Index;
