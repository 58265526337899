import React from 'react'
import Illustrations from '../../assets/Illustrations'

const Hiring = () => {
  return (
    <div className="employee-journey-flex">
          <div className="employee-journey-flex-left">
            <img src={Illustrations.Experience} alt="" />
          </div>
          <div className="employee-journey-flex-right">
            <h1>Experience accelerated, automated onboarding</h1>
            <p>
              Effortlessly hire and onboard staff with workflows that require
              minimal manual intervention.
            </p>
            <p>
              Effortlessly hire and onboard staff with workflows that require
              minimal manual intervention.
            </p>

            <p>
              Effortlessly hire and onboard staff with workflows that require
              minimal manual intervention.
            </p>
          </div>
        </div>
  )
}

export default Hiring