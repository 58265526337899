import React from "react";
import Illustrations from "../../assets/Illustrations";

const Enablement = () => {
  return (
    <div className="employee-journey-flex">
      <div className="employee-journey-flex-left">
        <img src={Illustrations.Enablement} alt="" />
      </div>
      <div className="employee-journey-flex-right">
        <h1>
          Give your frontline staff access to critical information at their
          fingertips, whenever they need it.
        </h1>
        <p>
          Ensure the appropriate information is readily available when needed.
        </p>
        <p>
          Arm your employees with a chat-based knowledge repository that
          delivers precise, immediate responses to all inquiries.
        </p>

        <p>
          Eradicate communication delays or errors. Implement shift-compliance
          rules to guarantee every message receives the attention it deserves.
        </p>
      </div>
    </div>
  );
};

export default Enablement;
