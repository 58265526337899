import React, { useState } from "react";
import Icons from "../../assets/Icons";
// import DashboardIcons from "../../assets/DashboardIcons";

const Faq = () => {
  const faqData = [
    {
      question: "Why should my team adopt Knotify?",
      answer:
        "Knotify works through chat messages, a virtually ubiquitous technology used across all age groups. Thanks to this no additional training is necessary to interact with Knotify. Set up and go to watch your employees engage with Knotify.",
    },
    {
      question: "Why do I need another tool for engagement?",
      answer:
        "Low engagement rates are a significant barrier to business growth. Knotify is an effective tool that helps you engage with your employees in a way they prefer  through messaging apps and chatbots. This leads to higher engagement, improved productivity, and overall business success.In the modern age of the deskless workforce, its essential to have a tool that can reach all employees, regardless of location or technology access.",
    },
    {
      question: "How does Knotify streamline the hiring process?",
      answer:
        "Knotify automates various tasks throughout the hiring process, from scheduling interviews and sending offer letters to collecting necessary onboarding documents. This saves time for both the HR team and candidates while providing a professional and efficient experience.",
    },
    {
      question: "How expensive is Knotify?",
      answer:
        "Knotify begins at $2.50 per active team member per month. Depending on your staffing level and business needs, we can tailor a package that works for you. We're reasonably priced and offer a package of benefits to tempt even the most expense-resistant executive team.",
    },

    {
      question: "Do you integrate with every tool I use?",
      answer:
        "Knotify can integrate with the great majority of cloud based applications. We can discuss your needs and confirm wether Knotify works with your specific software package. Drop us a message or give us a call.",
    },
    {
      question: "Will my staff be open to receiving work comms on WhatsApp?",
      answer:
        "Yes, our research has shown that the typical employee is comfortable receiving work-related communication through messaging apps like WhatsApp. In fact, many prefer it over traditional methods such as email or phone calls. With Knotify, you can communicate with your staff through the platform they already use for personal messaging, making it a seamless and user-friendly experience for all parties involved.",
    },
  ];
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="faq">
      {faqData.map((item, index) => (
        <>
          <div
            onClick={() => toggleAccordion(index)}
            key={index}
            // className="faq-item"
            className={expandedIndex === index ? "faq-item active" : "faq-item"}
          >
            <div className="faq-item-flex">
              <div className="faq-left">
                <div className={`faq-question`}>{item.question}</div>
              </div>
              <div className="faq-right">
                <div className="faq-right-icon">
                  <img
                    src={expandedIndex === index ? Icons.Minus : Icons.Plus}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {expandedIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default Faq;
