import React, { useState, useEffect } from "react";
import Brand from "../../assets/Brand";
import Icons from "../../assets/Icons";
import "./navbar.css";
import NavbarDropdown from "../Dropdown/NavbarDropdown";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 10) {
      setIsNavbarFixed(true);
    } else {
      setIsNavbarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [activeLinkHower1, setactiveLinkHower1] = useState(false);
  const [activeLinkHower2, setactiveLinkHower2] = useState(false);

  return (
    <>
      <div className={`menu ${showMenu ? "active" : ""}`}>
        <div className="menu-bg">
          <div className="menu-nav">
            <div onClick={() => navigate("/")} className="logo">
              <img src={Brand.Logo} alt="" />
            </div>
            <div onClick={() => setShowMenu(!showMenu)} className="nav-menu">
              <img src={Icons.Cross} alt="" />
            </div>
          </div>
          <div className="menu-block">
            <div className="menu-header">MENU</div>
            <div className="menu-items">
              <div className="menu-item active-menu-item">
                <p>User Cases</p>
                <div className="menu-item-sub-items">
                  <div
                    onClick={() => navigate("/hiring")}
                    className="navbar-dropdown-list-item"
                  >
                    <img src={Icons.Hiring} alt="" />
                    <div className="navbar-dropdown-list-content">
                      <h1>Hiring</h1>
                      <p>Automate hiring from application to day 1.</p>
                    </div>
                  </div>

                  <div
                    onClick={() => navigate("/training")}
                    className="navbar-dropdown-list-item"
                  >
                    <img src={Icons.Training} alt="" />
                    <div className="navbar-dropdown-list-content">
                      <h1>Training</h1>
                      <p>
                        Ensure your frontline knows how to get the job done.
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/announcements")}
                    className="navbar-dropdown-list-item"
                  >
                    <img src={Icons.Announcement} alt="" />
                    <div className="navbar-dropdown-list-content">
                      <h1>Announcements</h1>
                      <p>Reach your frontline with confidence every time.</p>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/engagement")}
                    className="navbar-dropdown-list-item"
                  >
                    <img src={Icons.Engagemet} alt="" />
                    <div className="navbar-dropdown-list-content">
                      <h1>Engagement</h1>
                      <p>
                        Collect actionable workforce insights and reduce churn.
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/knwoledgebase")}
                    className="navbar-dropdown-list-item"
                  >
                    <img src={Icons.KnowLedgeBase} alt="" />
                    <div className="navbar-dropdown-list-content">
                      <h1>Knowledge Base</h1>
                      <p>
                        Reduce errors and overhead through knowledge self serve
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-item">
                <p>Resources</p>
              </div>
              <div onClick={() => navigate("/pricing")} className="menu-item">
                <p>Pricing</p>
              </div>
              <div className="menu-item">
                <p>What’s New?</p>
              </div>
            </div>
            <div className="buttons | buttons-menu">
              {/* <button className="secondary">Sign in</button> */}
              <button
                onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}
              >
                Book your Demo !
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`navbar-wrap ${isNavbarFixed ? "fixed-navbar" : ""}`}>
        <div className="navbar">
          <div onClick={() => navigate("/")} className="logo">
            <img src={Brand.Logo} alt="" />
          </div>
          <div className="nav-right">
            <div className="nav-links">
              <div
                onClick={() => setShowDropdown(!showDropdown)}
                onMouseEnter={() => {
                  setShowDropdown(true);
                  setactiveLinkHower1(true);
                }}
                className={`nav-links-item 
                ${activeLinkHower1 ? "active-item" : ""}
                ${
                  showDropdown
                    ? "nav-links-item | active-item-rotate"
                    : "nav-links-item"
                }`}
              >
                Use Cases <img src={Icons.DownArrow} alt="" />
                {showDropdown && (
                  <div
                    onMouseLeave={() => {
                      setShowDropdown(false);
                      setactiveLinkHower1(false);
                    }}
                  >
                    <NavbarDropdown />
                  </div>
                )}
              </div>
              {/* <div className="nav-links-item">Resources</div> */}
              <div
                onClick={() => navigate("/pricing")}
                className={`nav-links-item
                ${activeLinkHower2 ? "active-item" : ""}
                `}
                onMouseEnter={() => {
                  setactiveLinkHower2(true);
                  setactiveLinkHower1(false);
                  setShowDropdown(false);
                }}
                onMouseLeave={() => {
                  setactiveLinkHower2(false);
                }}
              >
                Pricing
              </div>
              {/* <div className="nav-links-item">What’s New?</div> */}
            </div>
            <div className="buttons | nav-btns">
              {/* <button className="secondary">Sign in</button> */}
              <button
                onClick={() => {
                  window.open("https://tally.so/r/3197GO");
                }}
              >
                Book your Demo !{" "}
              </button>
            </div>
          </div>
          <div onClick={() => setShowMenu(!showMenu)} className="nav-menu">
            <img src={Icons.Menu} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
