import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const Privacy = () => {
  return (
    <>
      <div className="privacy-poilicy">
        <Navbar />
        <div className="privacy-poilicy-header">
          <h1>Privacy Policy</h1>
        </div>
        <div className="privacy-content">
          <b> 1. Introduction</b> <br />
          <br /> These Terms of Service ("ToS") govern your use of the Knotify
          marketing website, the Knotify web app, and the Knotify APIs, provided
          by Krishna Analytics Pte Ltd, a company registered in
          Singapore("Company", "we", "us", or "our"). By registering for,
          accessing, or using our services, you agree to be bound by these ToS.
          If you do not agree to these terms, please do not access or use the
          Knotify website, web app, or APIs.
          <br />
          <br />
          <b> 2. Eligibility</b>
          <br />
          <br />
          Our services are intended for use by businesses. By registering for
          and using our services, you represent that you are authorized to act
          on behalf of the business you represent.
          <br />
          <br />
          <b> 3. Account Registration</b>
          <br />
          <br />
          You may register for our services online or through a demo, after
          which we will register you. During registration, we collect
          information including your first name, last name, email address,
          mobile number, and company name.
          <br />
          <br />
          <b> 4. Payment Terms</b>
          <br />
          <br />
          Pricing for our services is currently not public and is provided via
          custom quotes. Accepted payment methods include credit cards and
          electronic bank transfers. Refunds, if any, will be handled on a
          case-by-case basis. There are no late fees or penalties associated
          with our services. In the case of non-payment, we will suspend our
          services.
          <br />
          <br />
          <b> 5. Account Management</b>
          <br />
          <br />
          Users can have multiple accounts or users under one account. You may
          modify or update your account information online via the Knotify web
          app. We reserve the right to terminate or suspend your account if you
          breach these ToS, engage in fraudulent activity, or engage in any
          activity that may harm the Company or its users.
          <br />
          <br />
          <b> 6. Data Privacy and Security</b>
          <br />
          <br />
          Please refer to our Privacy Policy for information on how we collect,
          use, store, and share user data, as well as the security measures in
          place to protect user data.
          <br />
          <br />
          <b>7. Intellectual Property</b>
          <br />
          <br />
          You retain all rights to the content you upload or generate through
          our services. However, by uploading content, you grant us a
          non-exclusive, worldwide, royalty-free, sublicensable, and
          transferable license to use, reproduce, modify, adapt, publish, and
          display such content in connection with providing our services. We do
          not claim any ownership rights in your content, and nothing in these
          ToS will be deemed to restrict any rights that you may have to use and
          exploit your content.
          <br />
          <br />
          <b>8. Third-Party Integrations and Services</b>
          <br />
          <br />
          The Knotify web app and APIs integrate with third-party services such
          as Auth0 for authentication and Twilio for communication. Your use of
          these services is subject to their respective terms and conditions.
          <br />
          <br />
          <b>9. Service Availability and Support</b>
          <br />
          <br />
          We strive to provide a high level of service availability but do not
          guarantee a specific uptime percentage. Our support policies and
          response times will be communicated to you upon registration.
          <br />
          <br />
          <b>10. Limitation of Liability</b>
          <br />
          <br />
          To the maximum extent permitted by law, our liability for damages
          arising from the use of our services will be limited to the amount you
          have paid to us in the past 12 months.
          <br />
          <br />
          <b>11. Indemnification</b>
          <br />
          <br />
          You agree to indemnify and hold harmless the Company, its affiliates,
          and their respective officers, directors, employees, and agents from
          any claims, liabilities, damages, losses, or expenses, including
          reasonable attorneys' fees, arising out of or in connection with your
          use of our services or your breach of these ToS.
          <br />
          <br />
          <b>12. Dispute Resolution and Governing Law</b>
          <br />
          <br />
          These ToS are governed by the laws of Singapore. Any disputes arising
          out of or relating to these ToS or your use of our services will be
          resolved through good faith negotiation between the parties. If the
          dispute cannot be resolved through negotiation, the parties agree to
          submit the dispute to arbitration under the Singapore International
          Arbitration Centre (SIAC) Rules, with the seat of arbitration in
          Singapore. The language of the arbitration will be English.
          <br />
          <br />
          <b>13. Modifications to the ToS</b>
          <br />
          <br />
          We reserve the right to modify these ToS at any time. We will notify
          you of any changes via email. Your continued use of our services after
          receiving notice of changes constitutes your acceptance of the updated
          ToS. If you do not agree to the updated terms, you must discontinue
          your use of our services.
          <br />
          <br />
          <b>14. Contact Information</b>
          <br />
          <br />
          If you have any questions or concerns about these ToS or our services,
          please contact us at admin@Knotify.io.
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
